import React, { Component } from 'react'
import Layout from '../components/layout'
import Intro from '../components/mask'
import SEO from '../components/seo'
import Carousel from '../components/carousel'

import AboutSection from '../components/aboutSection'
import Card from '../components/card'
import { MDBRow, MDBCol } from 'mdbreact'
import { Link, graphql } from 'gatsby'

export default ({ data }) => {
  console.log(data)
  return (
    <>
      <Layout>
        <SEO title="Vinnarna" keywords={[`Snyggast Kontor`, `Newstt`]} />
        <Carousel />
        <Intro />
        {/**/}
        <main>
          <AboutSection />
          <section id="cardSection">
      {/*       <h2 className="h1-responsive text-center font-weight-bold">
              <a id="topp25" />
              Topp 25
            </h2> */}

            {/* START */}
            <MDBRow className="m-0 mb-3" center>
              {data.allMarkdownRemark.edges.map(({ node }, index) => (
                <div key={node.id}>
                  <MDBCol size="12" className="mb-5">
                    <Card
                      node={node}
                      title={node.frontmatter.title}
                      excerpt={node.excerpt}
                      slug={node.fields.slug}
                      cover={node.frontmatter.thumbnail}
                    />
                  </MDBCol>
                </div>
              ))}{' '}
            </MDBRow>
            {/* STOP         {cover={node.frontmatter.image.childImageSharp.fluid}} */}
          </section>
        </main>
      </Layout>
    </>
  )
}

export const query = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      totalCount
      edges {
        node {
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY")
            thumbnail {
              childImageSharp {
                fluid(maxWidth: 600, quality: 100) {
                  originalImg
                }
              }
            }
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`
